import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import CustomInputField from "../../components/custom_input_field";
import { registerUser } from "../../../redux/auth_slice/register_user_slice";
import logo from "../../../../assets/sports_assests/z-logo.png";
import { useHistory } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { getCitiesList } from "../../../redux/zsports_slice/zsports_slice";
import { toast } from "react-toastify";
import "../../../../styles/file-input-custom.scss";

export default function RegisterScreen() {
  const { loading } = useSelector((state) => state.register);
  const dispatch = useDispatch();
  const history = useHistory();

  const { citiesList } = useSelector((state) => state.sportsComplex);

  useEffect(() => {
    dispatch(getCitiesList());
  }, [dispatch]);

  const [showDialog, setShowDialog] = useState(false);

  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
      pin: "",
      name: "",
      complexName: "",
      cityId: "",
      image: null,
      email: "",
      sportsComplexAddress: "",
    },
    validationSchema: Yup.object().shape({
      pin: Yup.string()
        .required("PIN is required.")
        .length(4, "PIN must be 4 digits long."),
      name: Yup.string().required("Name is required."),
      complexName: Yup.string().required("Complex Name is required."),
      cityId: Yup.string().required("City is required"),
      email: Yup.string().required("Email is required"),
      image: Yup.mixed().required("Complex Image is required"),
      sportsComplexAddress: Yup.string().required(
        "Complex Address  is required"
      ),
      mobileNumber: Yup.string()
        .required("Mobile Number is required.")
        .matches(
          /^03\d{9}$/,
          "Mobile Number must start with 03 and be 11 digits long."
        )
        .length(11, "Mobile Number must be 11 digits long."),
    }),
    onSubmit: async (data) => {
      dispatch(registerUser(data)).then((res) => {
        const responseCode = res?.payload?.responseCode;
        const responseDescription = res?.payload?.responseDescription;

        if (responseCode === "00") {
          toast.success("Successfully Registered");
          showSuccessDialog();
        } else if (responseCode === "500") {
          toast.error(responseDescription);
        }
      });
    },
  });

  const showSuccessDialog = () => {
    setShowDialog(true);
  };

  const handleRedirect = () => {
    history.push("/login");
  };

  const dialogFooter = (
    <div>
      <Button label="Okay" icon="pi pi-check" onClick={handleRedirect} />
    </div>
  );
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        formik.setFieldValue("image", base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <ConfirmDialog
        header="Success"
        visible={showDialog}
        footer={dialogFooter}
        message={
          <div style={{ textAlign: "center", lineHeight: "1.5" }}>
            Registered Successfully!
            <br />
            It is now awaiting approval from the checker.
          </div>
        }
      />

      <div className="auth_main">
        <div className="content">
          <img src={logo} className="logo-img" />
          <div className="auth_login_box">
            <h2 className="auth-welcome mt-3 text-align-center">Signup</h2>

            <form
              className=" p-fluid justify-content-center align-items-left mt-5"
              onSubmit={formik.handleSubmit}
            >
              <CustomInputField
                keyfilter={/^[a-zA-Z\s]*$/}
                maxLength={30}
                label="Name"
                iden="name"
                formik={formik}
                placeHolder="Name"
                type="text"
                icon="pi-user"
              />
              <CustomInputField
                keyfilter={/^[a-zA-Z\s]*$/}
                maxLength={30}
                label="Complex Name"
                iden="complexName"
                formik={formik}
                placeHolder="Complex Name"
                type="text"
                icon="pi-building-columns"
              />
              <CustomInputField
                label="Email"
                iden="email"
                formik={formik}
                placeHolder="Email"
                type="email"
                icon="pi-envelope"
              />
              <CustomInputField
                label="Mobile Number"
                iden="mobileNumber"
                formik={formik}
                placeHolder="Mobile Number"
                type="text"
                maxLength={11}
                onKeyUp={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("mobileNumber", e.target.value);
                }}
                icon="pi-mobile"
              />
              <CustomInputField
                maxLength={50}
                label="Sports Complex Address"
                iden="sportsComplexAddress"
                formik={formik}
                placeHolder="Sports Complex Address"
                type="text"
                icon="pi pi-map-marker" 
              />
              <CustomInputField
                label="Pin"
                iden="pin"
                formik={formik}
                placeHolder="Pin"
                type="password"
                feedback={false}
                maxLength={4}
                onKeyUp={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("pin", e.target.value);
                }}
                icon="pi-lock"
              />

              <div className="file-input">
                <label htmlFor="image" className="choose-file-button ">
                  File
                </label>
                <input
                  name="image"
                  type="file"
                  id="image"
                  accept=".png, .jpg, .jpeg, .svg"
                  onChange={handleFileUpload}
                  className="choose-image-none"
                />
                {formik.touched.image && formik.errors.image ? (
                  <small className="p-error">{formik.errors.image}</small>
                ) : null}
              </div>

              <CustomInputField
                iden="cityId"
                formik={formik}
                type="dropdown"
                label="City"
                placeHolder="Select City"
                options={citiesList.map((city) => ({
                  label: city.cityName,
                  value: city.cityId,
                }))}
                optionLabel="label"
                optionValue="value"
                filter={true}
                icon="pi-map-marker"
              />

              <br />
              <Button
                type="submit"
                className="customButton"
                label="Signup"
                loading={loading}
              />
            </form>
            <p className="text-center mt-2 an-account">
              If you have an account? <a onClick={handleRedirect}>Login here</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
