import React, { useRef, useEffect } from "react";
import { FieldArray } from "formik";
import { Button } from "primereact/button";
import CourtForm from "./courtForm";

const SportForm = ({
  sport,
  sportIndex,
  setFieldValue,
  selectedSportImage,
}) => {

  const newCourtRef = useRef(null);
  const shouldScrollRef = useRef(false); 

  const addCourt = () => {
    const newCourt = {
      courtName: "",
      courtImage: "",
      slots: {
        timePerSlot: "",
        daysAvailable: "",
        daysAvailableArray: [],
        refreshTime: "",
        amountPerSlot: "",
        startTime: "",
        totalSlots: "",
      },
      slotsData: [],
    };

    setFieldValue(`availableSports[${sportIndex}].courts`, [
      ...sport.courts,
      newCourt,
    ]);

    shouldScrollRef.current = true;

  };

  const sportImage = selectedSportImage || sport.sportImage;

  
  useEffect(() => {
    if (shouldScrollRef.current && newCourtRef.current) {
      newCourtRef.current.scrollIntoView({ behavior: "smooth" });
      shouldScrollRef.current = false; 
    }
  }, [sport.courts.length]);

  return (
    <FieldArray name={`availableSports[${sportIndex}].courts`}>
      {({ push, remove }) => (
        <div>
          {sport?.courts?.map((court, courtIndex) => (
            <div
              key={courtIndex}
              className="court-section"
              ref={courtIndex === sport.courts.length - 1 ? newCourtRef : null} 
            >
              <div
                className="form-heading d-flex"
                style={{ gap: "10px", alignItems: "center" }}
              >
                {sportImage ? (
                  <img
                    src={sportImage}
                    alt="Sport"
                    style={{
                      borderRadius: "50px",
                      width: "40px",
                      height: "40px",
                      border: "2px solid #509793",
                      objectFit: "none",
                    }}
                  />
                ) : (
                  ""
                )}
                <h5>Court {courtIndex + 1}</h5>
              </div>
              <div className="court-content">
                <CourtForm
                  court={court}
                  courtIndex={courtIndex}
                  setFieldValue={setFieldValue}
                  sportIndex={sportIndex}
                />
                <div className="button-group-right">
                  <Button
                    type="button"
                    icon="pi pi-plus"
                    className="p-button-success court-button"
                    onClick={addCourt}
                  />
                  <Button
                    type="button"
                    icon="pi pi-minus"
                    className="p-button-danger court-button"
                    disabled={sport.courts.length == 1}
                    onClick={() => remove(courtIndex)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </FieldArray>
  );
};

export default SportForm;
