import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import ActionButtons from "../../../components/action_buttons";
import {
  createTeller,
  updateTeller,
  getTellersList,
  getCitiesWithSportsComplexesList,
} from "../../../../redux/user_slice/teller_slice";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";

const EditAddForm = ({ onHide, formData, currentPage, rowsPerPage }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.loginUser);

  const tellerReducer = useSelector((state) => state.tellerManagement || {});
  const { complexListTeller } = tellerReducer;

  const [isImageEdit, setIsImageEdit] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [fileName, setFileName] = useState(
    formData?.profileImagePath ? formData?.profileImagePath : ""
  );

  const [selectedCity, setSelectedCity] = useState(null);
  const [complexOptions, setComplexOptions] = useState([]);

  useEffect(() => {
    dispatch(
      getCitiesWithSportsComplexesList({
        token: user.token,
      })
    );
  }, [dispatch, user.token]);

  useEffect(() => {
    if (formData?.sportComplex?.cityId) {
      const selectedCityData = complexListTeller.find(
        (city) => city.cityId === formData.sportComplex.cityId
      );
      if (selectedCityData) {
        setComplexOptions(selectedCityData.sportsComplexes || []);
      }
    }
  }, [formData, complexListTeller]);

  const formik = useFormik({
    initialValues: {
      name: formData?.name || "",
      email: formData?.email || "",
      cityId: formData?.sportComplex?.cityId || "",
      complexName: formData?.sportComplex?.complexName || "",
      mobileNumber: formData?.phoneNumber || "",
      isActive: formData?.isActive.toString(),
      newPin: "",
      pin: "",
      profileImagePath: formData?.profileImagePath
        ? formData.profileImagePath
        : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      cityId: Yup.string().required("City is required"),
      complexName: Yup.string().required("Complex name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      mobileNumber: Yup.string()
        .required("Mobile Number is required.")
        .matches(
          /^03\d{9}$/,
          "Mobile Number must start with 03 and be 11 digits long."
        )
        .length(11, "Mobile Number must be 11 digits long."),
      ...(formData
        ? {}
        : {
            pin: Yup.string()
              .required("PIN is required.")
              .length(4, "PIN must be 4 digits long."),
          }),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      if (!formChanged) {
        onHide();
        return;
      }

      const updatePayload = {
        id: formData?.id,
        ...values,
        isActive: values.isActive === "true",
      };

      try {
        const action = formData
          ? updateTeller({ ...updatePayload, token: user?.token })
          : createTeller({ ...values, token: user?.token });

        const response = await dispatch(action).unwrap();

        await dispatch(
          getTellersList({
            token: user.token,
            page: currentPage,
            recordsPerPage: rowsPerPage,
          })
        ).unwrap();

        onHide();
      } catch (error) {
        console.error("Error in form submission:", error);
        console.error("Error details:", error.response?.data || error.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleFieldChange = (field, value) => {
    formik.setFieldValue(field, value);
    setFormChanged(true);
  };

  const handleFileUpload = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = async () => {
        let base64String = reader.result;
        if (base64String.includes("base64,")) {
          base64String = base64String.split("base64,")[1];
        }
        setFileName(file.name);
        setIsImageEdit(true);
        handleFieldChange("profileImageBase64", base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCityChange = (e) => {
    const selectedCityId = e.value;
    setSelectedCity(selectedCityId);
    formik.setFieldValue("cityId", selectedCityId);

    const selectedCityData = complexListTeller.find(
      (city) => city.cityId === selectedCityId
    );

    if (selectedCityData) {
      setComplexOptions(selectedCityData.sportsComplexes || []);
      formik.setFieldValue("complexName", "");
    } else {
      setComplexOptions([]);
    }
  };

  const activeOptions = [
    { label: "Active", value: "true" },
    { label: "Non-active", value: "false" },
  ];

  const handleCancel = () => {
    onHide();
  };

  return (
    <>
      <div className="main-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid formgrid grid">
            <div className="field md:col-6 responsive-col">
              <label htmlFor="name">
                Name
                {!formData && <span className="asteric">&nbsp;*</span>}
              </label>
              <InputText
                keyfilter={/^[a-zA-Z\s]*$/}
                id="name"
                maxLength={30}
                value={formik.values.name}
                placeholder="Add name"
                onChange={(e) => handleFieldChange("name", e.target.value)}
              />
              {formik.touched.name && formik.errors.name && (
                <small className="p-error">{formik.errors.name}</small>
              )}
            </div>
            {!formData && (
              <div className="field md:col-6 responsive-col">
                <label htmlFor="mobileNumber">
                  Mobile Number{" "}
                  {!formData && <span className="asteric">&nbsp;*</span>}
                </label>
                <InputText
                  maxLength={11}
                  id="mobileNumber"
                  keyfilter="num"
                  name="mobileNumber"
                  placeholder="Enter mobile number"
                  value={formik.values.mobileNumber}
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                    handleFieldChange("mobileNumber", sanitizedValue);
                  }}
                  className={
                    formik.errors.mobileNumber && formik.touched.mobileNumber
                      ? "p-invalid"
                      : ""
                  }
                />
                {formik.errors.mobileNumber && formik.touched.mobileNumber && (
                  <small className="p-error">
                    {formik.errors.mobileNumber}
                  </small>
                )}
              </div>
            )}
            {!formData && (
              <div className="field md:col-6 responsive-col">
                <label htmlFor="pin">
                  Pin {!formData && <span className="asteric">&nbsp;*</span>}
                </label>
                <Password
                  toggleMask
                  autoComplete="new-password"
                  id="pin"
                  name="pin"
                  feedback={false}
                  maxLength={4}
                  placeholder="Enter pin"
                  value={formik.values.pin}
                  onChange={formik.handleChange}
                  onKeyUp={(e) => {
                    const sanitizedValue = e.target.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                    handleFieldChange("pin", sanitizedValue);
                  }}
                />
                {formik.errors.pin && formik.touched.pin && (
                  <small className="p-error">{formik.errors.pin}</small>
                )}
              </div>
            )}

            {formData && (
              <div className="field md:col-6 responsive-col">
                <label htmlFor="newPin">New Pin</label>
                <Password
                  toggleMask
                  autoComplete="new-password"
                  id="newPin"
                  name="newPin"
                  feedback={false}
                  maxLength={4}
                  placeholder="Enter new pin"
                  value={formik.values.newPin}
                  onChange={(e) => handleFieldChange("newPin", e.target.value)}
                  onKeyUp={(e) => {
                    const sanitizedValue = e.target.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                    handleFieldChange("newPin", sanitizedValue);
                  }}
                />
              </div>
            )}

            <div className="field md:col-6 responsive-col">
              <label htmlFor="email">
                Email {!formData && <span className="asteric">&nbsp;*</span>}
              </label>
              <InputText
                type="email"
                id="email"
                name="email"
                placeholder="Enter email"
                value={formik.values.email}
                onChange={(e) => handleFieldChange("email", e.target.value)}
                className={
                  formik.errors.email && formik.touched.email ? "p-invalid" : ""
                }
              />
              {formik.errors.email && formik.touched.email && (
                <small className="p-error">{formik.errors.email}</small>
              )}
            </div>

            {formData && (
              <div className="field md:col-6 responsive-col">
                <label htmlFor="isActive">Status</label>
                <Dropdown
                  id="isActive"
                  name="isActive"
                  options={activeOptions}
                  placeholder="Select status"
                  value={formik.values.isActive}
                  onChange={(e) =>
                    handleFieldChange("isActive", e.value.toString())
                  }
                />
              </div>
            )}

            <div className="field md:col-6 responsive-col">
              <label htmlFor="cityId">
                City {!formData && <span className="asteric">&nbsp;*</span>}
              </label>
              <Dropdown
                filter
                id="cityId"
                name="cityId"
                value={formik.values.cityId}
                options={complexListTeller.map((city) => ({
                  label: city.cityName,
                  value: city.cityId,
                }))}
                onChange={handleCityChange}
                placeholder="Select City"
                className={
                  formik.errors.cityId && formik.touched.cityId
                    ? "p-invalid"
                    : ""
                }
              />
              {formik.errors.cityId && formik.touched.cityId && (
                <small className="p-error">{formik.errors.cityId}</small>
              )}
            </div>

            <div className="field md:col-6 responsive-col">
              <label htmlFor="complexName">
                Complex Name{" "}
                {!formData && <span className="asteric">&nbsp;*</span>}
              </label>
              <Dropdown
                filter
                id="complexName"
                name="complexName"
                value={formik.values.complexName}
                options={complexOptions.map((complex) => ({
                  label: complex.sportsComplexName,
                  value: complex.sportsComplexName,
                }))}
                onChange={(e) => {
                  handleFieldChange("complexName", e.value);
                }}
                placeholder="Select Complex"
                className={
                  formik.errors.complexName && formik.touched.complexName
                    ? "p-invalid"
                    : ""
                }
              />
              {formik.errors.complexName && formik.touched.complexName && (
                <small className="p-error">{formik.errors.complexName}</small>
              )}
            </div>

            <div className="field md:col-6 responsive-col">
              <label htmlFor="ProfileImageFile">Profile Image</label>

              <div className="file-input">
                <input
                  type="text"
                  name="profileImageFile"
                  readOnly
                  value={fileName}
                  placeholder="No file chosen"
                />
                <label
                  htmlFor="ProfileImageFile"
                  className="choose-file-button"
                >
                  Choose File
                </label>
                <input
                  name="profileImageFile"
                  type="file"
                  id="ProfileImageFile"
                  accept=".png, .jpg, .jpeg, .svg"
                  onChange={handleFileUpload}
                  className="choose-image-none"
                />
              </div>
              {/* {formData?.profileImagePath && (
                <div className="preview-image">
                  <CustomImagePreview
                    src={formData.profileImagePath}
                    alt="Profile"
                  />
                </div>
              )} */}
            </div>
          </div>
          <div className="mt-5">
            <ActionButtons
              showSave
              onCancel={handleCancel}
              onSave={formik.handleSubmit}
              loading={formik.isSubmitting}
              saveLabel={formData ? "Update" : "Add"}
              cancelLabel="Close"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAddForm;
